<template>
  <div :class="['flex flex-col gap-13', containerClass]">
    <label v-if="label" class="text-18 font-bold leading-normal" :for="fieldId">
      {{ label }}
    </label>
    <div class="flex flex-col gap-6">
      <input
        v-bind="$attrs"
        :id="fieldId"
        :class="[
          'form-control',
          classInput,
          (errorMessage || $slots.error) && 'border-danger',
        ]"
        :name="name"
        :type="type"
        :value="value"
        :placeholder="placeholder"
        :min="rules?.min_value || undefined"
        :max="rules?.max_value || undefined"
        :maxlength="rules?.max || undefined"
        @input="handleChange"
        @blur="handleBlur"
      />
      <div v-if="errorMessage || $slots.error" class="text-12/16 text-danger">
        <slot name="error" :message="errorMessage">{{ errorMessage }}</slot>
      </div>
      <slot name="help"></slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useField } from 'vee-validate';
import { formIdInjectionKey } from '~/components/Form/setFormBackendErrors';

defineOptions({ inheritAttrs: false });

type Rules = {
  required?: boolean;
  email?: boolean;
  tel?: boolean;
  min?: number;
  min_value?: number;
  max?: number;
  max_value?: number;
  eth?: boolean;
  confirmed?: string;
};

type Props = {
  name: string;
  label?: string;
  labelShort?: string;
  rules?: Rules;
  placeholder?: string;
  type?: 'text' | 'tel' | 'email' | 'date' | 'password' | 'number';
  classInput?: string;
  min?: number;
  max?: number;
  containerClass?: string;
};

const props = withDefaults(defineProps<Props>(), {
  label: undefined,
  labelShort: undefined,
  rules: undefined,
  placeholder: undefined,
  type: 'text',
  classInput: undefined,
  min: undefined,
  max: undefined,
  containerClass: undefined,
});

const name = toRef(() => props.name);
const rules = computed(() => {
  const rules = { ...props.rules };

  if (props.type === 'email') {
    rules.email = true;
  } else if (props.type === 'tel') {
    rules.tel = true;
  }

  if (props.min) {
    rules.min_value = props.min;
  }

  if (props.max) {
    rules.max_value = props.max;
  }

  return rules;
});

const { value, errorMessage, handleBlur, handleChange } = useField(
  name,
  rules,
  { label: toRef(() => props.labelShort || props.label) },
);

const formId = inject(formIdInjectionKey, 'form');
const fieldId = toRef(() => [formId, props.name].join('_'));
</script>
