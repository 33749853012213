import {
  confirmed,
  email,
  max,
  max_value,
  min,
  min_value,
  required,
} from '@vee-validate/rules';
import isEthereumAddress from 'validator/es/lib/isEthereumAddress';
import { configure, defineRule } from 'vee-validate';

export const formIdInjectionKey = Symbol('formId');

defineRule('required', required);
defineRule('email', email);
defineRule('min', min);
defineRule('min_value', min_value);
defineRule('max', max);
defineRule('max_value', max_value);
defineRule('confirmed', confirmed);

function isEmpty(value: unknown): boolean {
  if (value === null || value === undefined || value === '') {
    return true;
  }

  return Array.isArray(value) && value.length === 0;
}

defineRule('tel', (value: unknown) => {
  if (isEmpty(value)) {
    return true;
  }

  const re = /^\+?([()-.\s]?\d+){6,}$/;

  if (Array.isArray(value)) {
    return value.every((val) => re.test(String(val)));
  }

  return re.test(String(value));
});

defineRule('password', (value: unknown) => {
  if (isEmpty(value)) {
    return true;
  }

  const re = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*]).{8,}$/;

  if (Array.isArray(value)) {
    return value.every((val) => re.test(String(val)));
  }

  return re.test(String(value));
});

defineRule('eth', (value: string) => {
  if (isEmpty(value)) {
    return true;
  }

  return isEthereumAddress(value);
});

configure({
  generateMessage: (context) => {
    switch (context.rule?.name) {
      case 'required':
        return `The ${context.field} field is required`;
      case '0xaddress':
        return `The ${context.field} field must be a valid wallet 0x address`;
      case 'email':
        return `The ${context.field} field must be a valid email`;
      case 'tel':
        return `The ${context.field} field must be a valid phone number`;
      case 'password':
        return `The ${context.field} field must be a valid password`;
      case 'min':
        return `The ${context.field} field may not be less than ${context.rule?.params} characters`;
      case 'min_value':
        return `The ${context.field} field may not be less than ${context.rule?.params}`;
      case 'max':
        return `The ${context.field} field may not be greater than ${context.rule?.params} characters`;
      case 'max_value':
        return `The ${context.field} field may not be greater than ${context.rule?.params}`;
      default:
        return `The ${context.field} field is not valid`;
    }
  },
});
